<template>
<div>
  <div v-if="isMobileWidth === 1">
    <report-detail></report-detail>
  </div>
  <v-row v-else>
    <v-col cols="12">
      <report-detail></report-detail>
    </v-col>
  </v-row>
</div>
</template>

<script>
import ReportDetail from './components/ReportCampaign.vue'

export default {
  name: 'campaign',
  metaInfo: {
    title: 'Campaign Report'
  },
  components: {
    ReportDetail,
  },
  setup() {
    return {
      isMobileWidth:0
    }
  },
  mounted(){
    let order = this.$route.query.item
    order = this.decryptData(order)
    this.idCampaign = order

    if(order > 0){
      if(this.isMobileWidth === 0){
        if (localStorage.getItem('reloaded')) {
            localStorage.removeItem('reloaded');
        } else {
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
        window.scrollTo(0,0);
      }
    } else {
      this.$router.push({name:'/'});
    }

    window.scrollTo(0,0);
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    decryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
      let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
      return decryptData;
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  }
}
</script>
