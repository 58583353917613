<template>
  <div>
    <!-- MOBILE -->
    <div v-if="isMobileWidth === 1">
      <div v-if="isLoading">
        <div class="d-flex pl-5 pr-5">
          <v-card width="100%" elevation="0">
            <v-skeleton-loader
                class="pa-1"
                type="card"
                v-for="n in 3" :key="n"
              ></v-skeleton-loader>
          </v-card>
        </div>
      </div>
      <div v-else class="ml-5 mr-5 mb-10 pb-5">
        <div class="body-1 black--text font-weight-bold">
          <!-- Report {{ itemData[0].invoice}} -->
        </div>
        <div class="mt-0 pl-3 pr-3">
          <div class="col-sm-12 pl-0 pr-0">
            <v-card class="round" elevation="0" outlined color="primary">
              <div class="ma-2 row">
                <v-col cols="6" class="pa-1">
                  <v-card class="pa-2 round text-center fourth">
                    <p class="black--text caption mb-0">Total Influencers</p>
                    <p class="font-weight-bold title black--text mb-0">{{ itemData.length}}</p>
                  </v-card>
                </v-col>
                <v-col cols="6" class="pa-1">
                  <v-card class="pa-2 round text-center fourth">
                    <p class="black--text caption mb-0">Total Impressions</p>
                    <p class="font-weight-bold title black--text mb-0">{{ impressions | numberFormat }}</p>
                  </v-card>
                </v-col>
                <v-col cols="6" class="pa-1">
                  <v-card class="pa-2 round text-center fourth">
                    <p class="black--text caption mb-0">Total Reach</p>
                    <p class="font-weight-bold title black--text mb-0">{{ reach | numberFormat}}</p>
                  </v-card>
                </v-col>
                <v-col cols="6" class="pa-1">
                  <v-card class="pa-2 round text-center fourth">
                    <p class="black--text caption mb-0">Total Likes</p>
                    <p class="font-weight-bold title black--text mb-0">{{ likes | numberFormat }}</p>
                  </v-card>
                </v-col>
                <v-col cols="6" class="pa-1">
                  <v-card class="pa-2 round text-center fourth">
                    <p class="black--text caption mb-0">Total Comments</p>
                    <p class="font-weight-bold title black--text mb-0">{{ comments | numberFormat}}</p>
                  </v-card>
                </v-col>
                <v-col cols="6" class="pa-1">
                  <v-card class="pa-2 round text-center fourth">
                    <p class="black--text caption mb-0">Total Shares</p>
                    <p class="font-weight-bold title black--text mb-0">{{ shares | numberFormat }}</p>
                  </v-card>
                </v-col>
              </div>
            </v-card>
            <v-btn block rounded color="primary" class="text-capitalize mt-3" @click.prevent="downloadFile()"><i class="fas fa-download mr-2"></i> Download Excel</v-btn>
            <v-card color="fourth" v-for="list in itemData" :key="list.influencer_id+'A'" class="mt-5 round" elevation="0" outlined>
              <v-card-text class="text-center pt-2">
                <v-avatar color="primary" size="80">
                  <v-img :src="list.photo"/>
                </v-avatar>
                <p class="pt-2 text-capitalize text-18 font-weight-bold black--text mb-1">{{ list.influ_name }}</p>
                <v-divider></v-divider>
                <div class="row pt-3 pl-2 pr-2 text-left">
                  <v-col cols="6" class="pb-0 pa-1">
                    <p class="mb-0">Followers <b class="black--text">{{ list.platform === 'instagram' ? list.follower_ig : list.follower_tiktok | nFormatter}}</b></p>
                  </v-col>
                  <v-col cols="6" class="pb-0 pa-1">
                    <p class="mb-0">Impressions <b class="black--text">{{list.impressions | numberFormat}}</b></p>
                  </v-col>
                  <v-col cols="6" class="pb-0 pa-1">
                    <p class="mb-0">Reach <b class="black--text">{{list.reach | numberFormat}}</b></p>
                  </v-col>
                  <v-col cols="6" class="pb-0 pa-1">
                    <p class="mb-0">Likes <b class="black--text">{{list.likes | numberFormat}}</b></p>
                  </v-col>
                  <v-col cols="6" class="pb-0 pa-1">
                    <p class="mb-0">Comments <b class="black--text">{{list.comments | numberFormat}}</b></p>
                  </v-col>
                  <v-col cols="6" class="pb-0 pa-1">
                    <p class="mb-0">Shares <b class="black--text">{{list.shares | numberFormat}}</b></p>
                  </v-col>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
    </div>

    <!-- DESKTOP -->
    <div v-else>
      <div v-if="isLoading">
        <div class="row pl-0 pr-0">
          <v-col cols="12">
            <v-card color="transparent" elevation="0">
              <v-skeleton-loader
                  class="pa-1"
                  type="card"
                ></v-skeleton-loader>
            </v-card>
          </v-col>
          <v-col cols="3" v-for="n in 4" :key="n">
            <v-card width="100%" color="transparent" elevation="0">
              <v-skeleton-loader
                  class="pa-1"
                  type="card"
                  
                ></v-skeleton-loader>
            </v-card>
          </v-col>
        </div>
      </div>
      <div v-else class="">
        <div class="headline black--text font-weight-bold d-flex justify-space-between">
          Campaign Report
          <v-btn rounded color="primary" class="text-capitalize" @click.prevent="downloadFile()"><i class="fas fa-download mr-2"></i> Download Excel</v-btn>
        </div>
        <div class="mt-0">
          <div class="col-sm-12 pl-0 pr-0">
            <v-card  class="round" elevation="0" outlined color="primary">
              <div class="ma-2 row">
                <v-col cols="2" class="pa-1">
                  <v-card class="pa-2 round text-center fourth">
                    <p class="black--text mb-0">Total Influencers</p>
                    <p class="font-weight-bold title black--text mb-0">{{ itemData.length}}</p>
                  </v-card>
                </v-col>
                <v-col cols="2" class="pa-1">
                  <v-card class="pa-2 round text-center fourth">
                    <p class="black--text mb-0">Total Impressions</p>
                    <p class="font-weight-bold title black--text mb-0">{{ impressions | numberFormat }}</p>
                  </v-card>
                </v-col>
                <v-col cols="2" class="pa-1">
                  <v-card class="pa-2 round text-center fourth">
                    <p class="black--text mb-0">Total Reach</p>
                    <p class="font-weight-bold title black--text mb-0">{{ reach | numberFormat}}</p>
                  </v-card>
                </v-col>
                <v-col cols="2" class="pa-1">
                  <v-card class="pa-2 round text-center fourth">
                    <p class="black--text mb-0">Total Likes</p>
                    <p class="font-weight-bold title black--text mb-0">{{ likes | numberFormat }}</p>
                  </v-card>
                </v-col>
                <v-col cols="2" class="pa-1">
                  <v-card class="pa-2 round text-center fourth">
                    <p class="black--text mb-0">Total Comments</p>
                    <p class="font-weight-bold title black--text mb-0">{{ comments | numberFormat}}</p>
                  </v-card>
                </v-col>
                <v-col cols="2" class="pa-1">
                  <v-card class="pa-2 round text-center fourth">
                    <p class="black--text mb-0">Total Shares</p>
                    <p class="font-weight-bold title black--text mb-0">{{ shares | numberFormat }}</p>
                  </v-card>
                </v-col>
              </div>
            </v-card>
            <v-row class="mt-2" no-gutters>
              <v-col cols="3"  v-for="list in itemData" :key="list.influencer_id+'A'">
                <v-card min-height="220px" color="fourth" class="ma-3 round" elevation="0" outlined>
                  <v-card-text class="text-center pt-2 pl-2 pr-2">
                    <v-avatar color="primary" size="80">
                      <v-img :src="list.photo"/>
                    </v-avatar>
                    <p class="pt-2 text-capitalize text-18 font-weight-bold black--text mb-1">{{ list.influ_name }}</p>
                    <v-divider></v-divider>
                    <div class="row pt-3 pl-2 pr-2 text-left">
                      <v-col cols="6" class="pb-0 pa-1">
                        <p class="mb-0">Followers <b class="black--text">{{ list.platform === 'instagram' ? list.follower_ig : list.follower_tiktok | nFormatter}}</b></p>
                      </v-col>
                      <v-col cols="6" class="pb-0 pa-1">
                        <p class="mb-0">Impressions <b class="black--text">{{list.impressions | numberFormat}}</b></p>
                      </v-col>
                      <v-col cols="6" class="pb-0 pa-1">
                        <p class="mb-0">Reach <b class="black--text">{{list.reach | numberFormat}}</b></p>
                      </v-col>
                      <v-col cols="6" class="pb-0 pa-1">
                        <p class="mb-0">Likes <b class="black--text">{{list.likes | numberFormat}}</b></p>
                      </v-col>
                      <v-col cols="6" class="pb-0 pa-1">
                        <p class="mb-0">Comments <b class="black--text">{{list.comments | numberFormat}}</b></p>
                      </v-col>
                      <v-col cols="6" class="pb-0 pa-1">
                        <p class="mb-0">Shares <b class="black--text">{{list.shares | numberFormat}}</b></p>
                      </v-col>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import axios from 'axios'

export default defineComponent({
  components: {
  },
  setup() {
    return{
      totalPrice:0,
      itemData:[],
      isLoading:true,
      loadingTable:true,
      total:0,
      serviceFee:0,
      idCampaign:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      itemsPerPageMobile: 5,
      index:0,
      level:[],
      impressions:0,
      reach:0,
      likes:0,
      comments:0,
      shares:0,
      saved:0,
      isMobileWidth:0
    }
  },
  mounted(){
    let id = this.$route.query.item
    this.idCampaign = this.decryptData(id)

    if(parseInt(this.idCampaign) > 0){
      this.loadReport()
    } else {
      this.$router.push({name:'report'});
    }

    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  computed: {
    headers() {
      return [
        {text: 'No.', value: 'num', sortable: false, width: "1%"},
        // {text: 'Paket', value: 'packages.name', sortable: false, width: "1%"},
        {text: 'Gambar', value: 'iron', sortable: false, width: "0.5%"},
        {text: 'Influencer', value: 'name', sortable: false, width: "1%"},
        {text: 'Follower', align: 'left', sortable: false, value: 'follower_ig', width: "1%"},
        {text: 'Upload', value: 'upload_date', sortable: false, width: "11%"},
        {text: 'Konten', value: 'packages.content', sortable: false, width: "30%"},
        {text: 'Reach', align: 'center', value: 'reach', sortable: false, width: "1%"},
        {text: 'Impressions',align: 'center',  value: 'impressions', sortable: false, width: "1%"},
        {text: 'Likes', align: 'center', value: 'likes', sortable: false, width: "1%"},
        {text: 'Comments', align: 'center', value: 'comments', sortable: false, width: "1%"},
        {text: 'Share', align: 'center', value: 'share', sortable: false, width: "1%"},
        {text: 'Saved', align: 'center', value: 'saved', sortable: false, width: "1%"},
        {text: 'Link Click', align: 'center', value: 'link_click', sortable: false, width: "1%"},
        // {text: 'Play', value: 'play', sortable: false, width: "1%"},
        // {text: 'Sticker Tap', value: 'sticker_taps', sortable: false, width: "1%"},
      ]
    },
  },
  methods:{
    decryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
      let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
      return decryptData;
    },
    loadReport(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'quotation/'+this.idCampaign, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          // console.log(response)
          if(response.status === 200){
            let data = response.data.data
            for(let a=0; a < data.length; a++){
              this.impressions += data[a].impressions ? parseInt(data[a].impressions) : 0
              this.reach += data[a].reach ? parseInt(data[a].reach) : 0
              this.likes += data[a].likes ? parseInt(data[a].likes) : 0
              this.comments += data[a].comments ? parseInt(data[a].comments) : 0
              this.shares += data[a].shares ? parseInt(data[a].shares) : 0
              axios({
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                headers: {
                  'Authorization': 'Bearer '+ token
                },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            this.itemData = data
            setTimeout(() => { 
              this.isLoading = false
            }, 2000);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    downloadFile() {
      let id = this.makeid(6)
      let token = localStorage.getItem('token')
      let getUser = JSON.parse(localStorage.getItem('user'));
      let client = getUser.business_name.replaceAll(' ', '_')
      axios({
        url: process.env.VUE_APP_API_ENDPOINT + 'quotation/exportCampaign/'+this.idCampaign,
        headers: {
          'Authorization': 'Bearer '+ token
        },
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        const blob = new Blob([res.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = 'Report_VDC_'+client+'_'+id+'.xlsx';
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
      }
      return result;
    },
    levelFormat(value){
      if(value === 'A'){
        return 'Nano'
      } else {
        return 'Micro'
      }
    },
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters: {
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    labelFormat (value) {
      return value.replace(/-/g,' ');
    },
    dateFormat(value){
      return value.toString().slice(0, 24)
    },
    dateFormat2(value){
      return value.toString().slice(0, 16)
    },
    levelFormat(value){
      if(value === 'A'){
        return 'Nano'
      } else {
        return 'Micro'
      }
    },
    nFormatter(num) {
      if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    }
  },
})
</script>